<template>
 <CCard>
  <CCardBody>
    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       
      pagination>
        <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
       <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
      <template #status="{item}">
          <td class='font-weight-bold' v-show="item.status == 1">Not checked</td>
          <td class='font-weight-bold' v-show="item.status == 2">Checked</td>
      
        </template>
        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'InputFieldValue', params: {sectionFormId: item.sectionFormId, mode: 'view'}}">Input Values </CDropdownItem>
           <CDropdownItem :to="{name: 'SubSectionFormData', params: {sectionFormId: item.sectionFormId, mode: 'view'}}">Subsection Form Data </CDropdownItem>
           
                             </CDropdown> 

          
        </td>
        
      </template>
  
      </CDataTable>
  </CCardBody>
   <div ref="modalArea"></div>
   <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
</template>

<script>
const fields = [
   { key: "index", _classes: 'font-weight-bold', _style: "min-width:200px" },
 { key: "sectionTitle", label: "Title", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "status", _classes: 'font-weight-bold', _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time",_classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "SectionFormData",
  components: {
     ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getSectionFormData() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/sections/data/"+this.$route.params.requestId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
cancel() {
   
             this.$router.push({ name: "WorkPermitRequests" });
    },
    navToNewSymptoms(){
       this.$router.push({ name: "SectionFormData", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getSectionFormData();
  },
};
</script>